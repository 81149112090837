// $theme-colors: (
//   "primary": #EB5556,
//   "secondary": #306FDB,
//   "success": #459473,
//   "danger": #CC264F,
//   "warning": #B04918,
//   "info": #DDEBFD,
// );

// $alert-bg-level: 0;
// $alert-color-level: 0;

// $info: #DDEBFD;

@import '~bootstrap/scss/bootstrap';
